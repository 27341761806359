import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: { main: '#000000' },
        red: { main: '#EE715B' },
        white: { main: "#FFFFFF" },
        gray: { main: '#C4C4C4' },
        lightGray: { main: '#5D5D60' },
        blue: { main: '#1DB0B9' }
    },
    typography: {
        fontFamily: 'Roboto',
        fontSize: 12,
        fontWeightRegular: 500,
        fontWeightBold: 700,
        button: {
            fontFamily: 'Roboto',
            fontSize: 16,
            fontWeight: 700,
            textTransform: 'none',
        },
        input: {
            fontFamily: 'Roboto',
            fontSize: 16,
            fontWeight: 700,
        },
        errorText: {
            fontFamily: 'Roboto',
            fontSize: 10,
            fontWeight: 500,
            color: '#EE715B', // red
        },
        subtitle: {
            fontSize: 16,
            textTransform: 'none',
            color: '#434343',
        },
        title: {
            fontFamily: 'Roboto',
            fontSize: 24,
            fontWeight: 700,
            textTransform: 'none',
            color: '#000000'
        },
        mediumText: {
            fontFamily: 'Roboto',
            fontSize: 14,
            fontWeight: 700,
            color: '#434343',
        },
        inputLabel: {
            fontFamily: 'Roboto',
            fontSize: 10,
            fontWeight: 700,
            color: '#00000061',
        },
        serviceText: {
            backgroundColor: '#C4C4C4',
            borderRadius: '3.125rem',
            textAlign: 'center',
            width: '6.25rem'
        }
    },
    globals: {
        cardWithNumber: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
            backgroundColor: '#ccc',
            width: '100%',
            height: 'auto',
            minWidth: 65,
            paddingTop: 5,
            paddingBottom: 5,
            borderRadius: 5,
            fontFamily: 'Roboto',
            fontWeight: 700,
            fontSize: 10,
            whiteSpace: 'nowrap',
        },
        center: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        },
        centerVertical: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }
    }
})

export default theme;