import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import Mockup from '../assets/images/Mockup.svg'
import Background from '../assets/images/blackbackground.svg'
import PrimaryButton from './primaryButton'
import theme from '../styles/theme';
import Man from '../assets/images/man.svg'
import Mans from '../assets/images/mans.svg'

const SectionFour = () => {

  const classes = useStyles();

  return (
    <div id='jobs' className={classes.container}>
      <div className={classes.imageAndTextContainer}>
        <div className={classes.floatContainer}>
          <div className={classes.mansContainer}>
            <img src={Mans} alt="icon" className={classes.mans} />
          </div>
          <div className={classes.textAndButtonContainer}>
            <div className={classes.firstText}>
              Únete a nuestro equipo.
            </div>
          </div>
          <div className={classes.secondText}>
            Mándanos tus inquietudes profesionales y con gusto revisaremos si puedes ser parte de la familia FlexeDigital.
          </div>
          <div className={classes.buttonContainer}>
            <PrimaryButton text='JOIN NOW' width={'45%'} color={theme.palette.blue.main} />
          </div>
        </div>
      </div>
      <div className={classes.imageContainer}>
        <img src={Man} alt="man" width={'100%'} />
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '62px',
      height: '100%',
      flexDirection: 'row',
      padding: '10% 0 10% 0',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    floatContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      top: '30%',
      width: '68%',

    },
    firstText: {
      fontSize: '62px',
      fontWeight: '900',
      width: '70%',
    },
    secondText: {
      padding: '0 0 0 20%',
      paddingTop: '35px',
      fontSize: '28px',
      color: '#5D5D60 ',
      width: '100%',  
      textAlign: 'justify',
    },
    blue: {
      color: theme.palette.blue.main,
    },
    imageAndTextContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    imageContainer: {
      display: 'flex',
      textAlign: 'center',
      width: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      }
    },
    icon: {
      display: 'flex',
      flexDirection: 'column',
      width: '25%',
      alignItems: 'center',
      textAlign: 'center',
      gap: '15px'
    },
    text: {
      fontSize: '62px'
    },
    mans: {
      zIndex: -1,
      position: 'absolute',
    },
    mansContainer: {
      width: '100%',
    },
    textAndButtonContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '80%',
      padding: '20% 0 0 0'
    },
    buttonContainer: {
      width: '80%',
      display: 'flex',
    }
  }),
);


export default SectionFour;