import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import NavBar from './navBar';
import Footer from './footer';

const Layout = (props) => {

  const classes = useStyles();

  return (
    <>
      <NavBar />
        {props.children}
      <Footer />
    </>

  )
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      // backgroundColor: 'red',
      width: '100%',
    },
  }),
);


export default Layout;
