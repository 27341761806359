import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import Background from '../assets/images/blackbackground.svg'
import PrimaryButton from './primaryButton'
import theme from '../styles/theme';
import NeritoLogo from '../assets/images/nerito.svg'
import FlexeAnalytics from '../assets/images/flexeanalytics.svg'
import NeritoPay from '../assets/images/neritopay.svg'


const SectionThree = () => {

  const classes = useStyles();

  return (
    <div id='groups' className={classes.container}>
      <div className={classes.cardContainer}>
        <div className={classes.card}>
          <img src={FlexeAnalytics} alt="FlexeAnalytics" className={classes.image} />
          <img src={NeritoLogo} alt="FlexeAnalytics" className={classes.image} />
          <img src={NeritoPay} alt="FlexeAnalytics" className={classes.image} />
        </div>
      </div>
      <div className={classes.textContainer}>
        <div className={classes.firstText}>
          <p>Nuestro grupo<span className={classes.blue}>.</span></p>
        </div>
        <div className={classes.secondText}>
          <p className={classes.paragraph}>Nos dedicamos a desarrollar servicios y soluciones especializadas con las herramientas tecnológicas adecuadas para potenciar la innovación y el mercado financiero global a través de plataformas de pago, comercio electrónico e inteligencia analítica para negocios y empresas.</p>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      color: 'white',
      fontSize: '62px',
      height: '100%',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${Background})`,
      padding: '5% 0 5% 0',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
      },
    },
    textContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      justifyContent: 'space-around',
      padding: '5%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '0',
        textAlign: 'center',
      },
    },
    firstText: {
      fontSize: '62px',
      fontWeight: '800',
      width: '50%',
      padding: '0 0 25% 0',
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        padding: '0 0 12% 0',
      },
    },
    secondText: {
      fontSize: '28px',
      color: '#DEDEE3',
      width: '85%',
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        textAlign: 'center',
      },
    },
    blue: {
      color: theme.palette.blue.main,
    },
    cardContainer: {
      width: '50%',
      padding: '5%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '5% 0 0 0 '
      },
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '100px',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    image: {
      [theme.breakpoints.down('md')]: {
        width: '80%',
      },

    },
    paragraph:{
      width: '100%',
      textAlign: 'justify',
      [theme.breakpoints.down('md')]: {
        width: '80%',
        textAlign: 'center',

      },
    },

  }),
);


export default SectionThree;