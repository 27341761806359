import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import Logo from '../../assets/images/Logo.svg'
import Divider from '../../assets/images/Divider.svg'
import { Link,NavLink } from "react-router-dom";

const Footer = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <NavLink to = {'/'}>
          <img src={Logo} alt='Logo' width='100%'/>
        </NavLink>
      </div>
      <div className={classes.divider}>
        <img src={Divider} alt="" />
      </div>
      <div className={classes.copyright}>
        <div className={classes.copyrightText}>
          Copyright © 2024 Flexedigital - Todos los derechos reservados.
        </div>
        <div className={classes.termsContainer}>
          <Link className={classes.link} to="/privacity">Aviso de privacidad</Link>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      backgroundColor: '#000000',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px'
    },
    copyright: {
      width: '50%',  
      display: 'flex',
      flexDirection: 'column',
      color: 'white'
    },
    termsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '70%'
    },
    divider:{
      display: 'flex',
      width: '20%',
      justifyContent: 'center'

    },
    logo:{
      width: '30%',
      justifyContent: 'center',
      display: 'flex',
    },
    copyrightText:{
      marginBottom: '10px',
    },
    link: {
      color: theme.palette.white.main,
      '&:link': {
        textDecoration: "none" 
      },
      '&:visited': {
        textDecoration: "none" 
      }
    }
  }),
);

export default Footer;