import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import PrimaryButton from '../../components/primaryButton'

import { makeStyles } from '@mui/styles';
import Map from '../../assets/images/map.svg'

import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import GoogleIcon from '@mui/icons-material/Google';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';

import { withSnackbar } from 'notistack';

import theme from '../../styles/theme'

const ContactsForm = (props) => {
  const classes = useStyle();
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [errors, setErrors] = useState(
    { errorName: false, errorEmail: false, errorMessage: false, errorSyntax: false })
  // let errors = {
  //   errorName: false,
  //   errorEmail: false,
  //   errorSyntax: false, 
  //   errorMessage: false
  // }
  const handleErrors = () => {
    let error = {}
    const regexEmail = /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    if (name === '') {
      error.name = "Favor de ingresar un Nombre"
      errors.errorName = true
      console.log('errors ', errors);
      setErrors(errors)
    }

    if (email === '') {
      error.email = "Favor de ingresar un Correo Electrónicos"
      errors.errorEmail = true
      console.log('errors ', errors);
      setErrors(errors)
    }

    if (message === '') {
      error.message = "Favor de escribir un Mensaje"
      errors.errorMessage = true
      console.log('errors ', errors);
      setErrors(errors)
    }

    if (!regexEmail.test(email)) {
      error.emailSyntax = "Favor de ingresar un Correo Electrónicos valido"
      errors.errorSyntax = true
      setErrors(errors)
    }
    return error
  }

  const senEmail = (event) => {
    event.preventDefault();
    const error = handleErrors()

    if (Object.keys(error).length === 0) {

      fetch('https://x6vujp4eyc.execute-api.us-west-2.amazonaws.com/dev/sendEmail', {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify({
          name: name,
          email: email,
          message: message
        }),

      })
        .then(response => response.json())
        .then(item => {
          setName('')
          setEmail('')
          setMessage('')
          handleAlert('success', 'Envio exitosa')
        })
        .catch(err => {
          console.log("Errr ", err);
          handleAlert('error', 'Ha ocurrido un error, por favor inténtelo de nuevo más tarde')
        })

    }
    else {
      for (const value in error) {
        handleAlert('error', error[value])
      }
    }
  }

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target
    const regexLetters = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ /s]*$/

    switch (name) {
      case 'name':
        if (regexLetters.test(value)) {
          setName(value)
        }
        break;

      case 'email':
        setEmail(value)
        break;

      case 'message':
        setMessage(value)
        break;

      default:
        break;
    }
  }

  return (
    <div className={classes.containerForm}>
      <form className={classes.form}>
        <div className={classes.inputContainer}>
          <TextField
            label="Nombre"
            variant="outlined"
            name='name'
            required
            value={name}
            onChange={handleOnChange}
            className={classes.inputs}
          />
        </div>
        <div className={classes.inputContainer}>
          <TextField
            label="Correo Electrónicos"
            variant="outlined"
            name='email'
            required
            value={email}
            onChange={handleOnChange}
            className={classes.inputs}
          />
        </div>
        <div className={classes.inputContainer}>
          <TextField
            label="Mensaje"
            placeholder="Mensaje"
            multiline
            required
            name='message'
            value={message}
            onChange={handleOnChange}
            className={classes.inputs}
            rows={6}
          />
        </div>
        <div className={classes.inputContainer}>
          <PrimaryButton text='SEND' width='100%' color={theme.palette.blue.main} />
        </div>
      </form>
      <div className={classes.containerContacts}>
        <div className={classes.containerContactInfo}>
          <div className={classes.iconContainer}>
            <div className={classes.icon}>
              <LocationOnOutlinedIcon fontSize='large' />
            </div>
            <div className={classes.iconText}>
              <p className={classes.label}>Ave Coahuila 68B, Col. Centro, Hermosillo, Sonora, CP 83000</p>
            </div>
          </div>
          <div className={classes.iconContainer}>
            <div className={classes.icon}>
              <LocalPhoneOutlinedIcon fontSize='large' />
            </div>
            <div className={classes.iconText}>
              <p className={classes.label}> +234 081-1236-4568</p>
            </div>
          </div>
          <div className={classes.iconContainer}>
            <div className={classes.icon}>
              <EmailOutlinedIcon fontSize='large' />
            </div>
            <div className={classes.iconText}>
              <p className={classes.label}>contacto@flexedigital.com</p>
            </div>
          </div>
          <div className={classes.socialMediaContainer}>
            <GoogleIcon fontSize="large" />
            <LinkedInIcon fontSize="large" />
            <FacebookIcon fontSize="large" />
          </div>
        </div>
        <div className={classes.map}>
            <img src={Map} alt="map" width='100%' />
          </div>
      </div>
    </div>
  )
}

const useStyle = makeStyles(theme => ({
  containerForm: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignContent: 'center',
    backgroundColor: theme.palette.white.main,
    borderRadius: '0.625rem',
    width: '100%',
    marginBottom: '6.25rem',
    paddingTop: 50,
    width: '100%',
    color: 'black',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: 20
    },
  },
  title: {
    ...theme.typography.title,
    fontSize: 72,
    color: theme.palette.primary.main,
    textAlign: 'left',
    lineHeight: 1,
    marginBottom: 10
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    gap: '10px',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },

  },
  containerButton: {
    backgroundColor: theme.palette.primary.main,
    width: '14.063rem',
    height: '3.125rem',
    borderRadius: '4.25rem',
    margin: '1.875rem 0 ',
    '&:hover': {
      cursor: 'pointer'
    },
    width: '100%'
  },
  labelButton: {
    color: theme.palette.white.main,
    fontSize: 16
  },
  img: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '50%'
    },
  },
  inputs: {
    width: '100%',
    marginTop: '3.125rem',
  },
  iconContainer: {
    display: 'flex',
    // justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    textAlign: 'center',
  },

  icons: {
    width: '10%',
  },
  containerContacts: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'space-around',
    width: '40%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexDirection: 'row'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center'
    },
  },
  socialMedia: {
    width: '33%',
    background: 'transparent',
  },
  socialMediaContainer: {
    display: 'flex',
    justifyContent: 'start',
    width: '100%',
    paddingLeft: '10%',
    gap: '10%',
  },
  containerLogoImage: {
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      width: '50%'
    },
  },
  containerContactInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    justifyContent: 'space-around',
    gap: '30px',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '50%',
      alignItems: 'space-around',

    },
  },
  label: {
    fontSize: '16px',
    textAlign: 'left'
  },
  inputContainer: {
    width: '100%',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    width: '20%'
  },
  iconText: {
    width: '80%',
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  map:{
    width: '100%',
    height: '20%',
    padding: '15px 0 0 0'
  }

}))

export default withSnackbar(ContactsForm);
