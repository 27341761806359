import React,{useEffect} from 'react';
import Layout from '../components/Layout'

import SectionOne from '../components/sectionOne';
import SectionTwo from '../components/sectionTwo';
import SectionThree from '../components/sectionThree';
import SectionFour from '../components/sectionFour';
import SectionFive from '../components/sectionFive';

const Landing = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
    </Layout>
  )
}

export default Landing;
