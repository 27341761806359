import axios from 'axios'
const getPrivacityPolice = async() => {
    const { REACT_APP_ENDPOINT_API_TERMS,REACT_APP_TERMS_TYPE,REACT_APP_TERMS_API_KEY } = process.env;
    
    try {
        const config = {
          method: 'get',
          url: `${REACT_APP_ENDPOINT_API_TERMS}/current/${REACT_APP_TERMS_TYPE}`,
          headers: { 
            'x-api-key': REACT_APP_TERMS_API_KEY
          }
        };
    
        const response = await axios.request(config)
        return { success: true, data: response.data.data.content}
      } 
      catch (error) {
        return {
          success: false,
          error: error.data
        }
      }
}

export default getPrivacityPolice