import React from 'react';
import { makeStyles } from '@mui/styles';
import '../../styles/styles.css'

const useStyle = makeStyles(theme =>({
    button: {
        backgroundColor: (props) => (props.color ? props.color : "#FFFFFF90"),
        width: (props) => (props.width ? props.width : "100%"),
        fontWeight: 'bold',
        height: '3.125rem',
        borderRadius: '4.25rem',
        margin: '1.875rem 0 ',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    label: {
        color: (props) => (props.color ? theme.palette.white.main : "#000000" ),
        fontSize: 16
    }
}))

const PrimaryButton = (props) => { 
    const classes = useStyle(props);
     
  return (
    <button className={classes.button}>
        <p className={classes.label}>{props.text}</p>
    </button>
  )
};

export default PrimaryButton;
