import React, {useState, useEffect} from 'react';
import { createStyles, makeStyles } from '@mui/styles';

import Layout from '../components/Layout'
import Background from '../assets/images/blackbackground.svg'
import getPrivacityPolice from '../lib/api/Documents/getPrivacityPolicies';

const Privacity = () => {
    const classes = useStyles();
    const [privacityPolice, setPrivacityPolice] = useState('')

    useEffect(() => {
      window.scrollTo(0, 0)
      getPrivacityP()
    }, [])
  
    const getPrivacityP = async() => {
      const response = await getPrivacityPolice()

      if(response.success){
        const split = response.data.split('<ol><li>')
        split.shift()
        const response2 = split.map((it,index) => `<ol start=${index + 1}><li>${it}` )
        const content = response2.join(" ")
        .replaceAll("<p style='text-align: right'>",`<p style="${styles.textRight}">`)
        .replaceAll('</p>","',"</p>")
        .replaceAll("<p>&nbsp; &nbsp; &nbsp; &nbsp;",`<p style="${styles.paragraphs}">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;`)
        .replaceAll("<p style='text-align: justify'>",`<p style="${styles.paragraphs}">`)
        .replaceAll("<p style='text-align:justify'>",`<p style="${styles.paragraphs}">`)
        .replaceAll("<h3 style='text-align: justify'>",`<h3 style="${styles.subtitle}">`)
        .replaceAll("<strong>",`<strong style="${styles.fontWeight}">`)
        .replaceAll("<li>",`<li style="${styles.paragraphs}">`)
        .replaceAll("style='font-size: 14px'",'')
        .replaceAll("style='color: rgb(0, 0, 0);font-size: 14px'",'')
        .replaceAll("style='color: rgb(0, 0, 0)'",'')
        .replaceAll('FlexeDigital',`<strong style="${styles.labelNerito}">FlexeDigital </strong>`)
        .replaceAll('FlexeDigital',`<strong style="${styles.labelNerito}">FlexeDigital </strong>`)
        setPrivacityPolice(content)
      }
      else {
        setPrivacityPolice('')
      }
    }

  return (
    <Layout>
      <main className={classes.container}>
        <div className={classes.title}>
          Aviso de privacidad
          <span className={classes.blue}>.</span>
        </div>
        <div className={classes.contact}>
          <div className={classes.containerForm} dangerouslySetInnerHTML={{__html: privacityPolice}}></div>
        </div>
      </main>
    </Layout>
  )
}

const styles = {
    paragraphs: `color: #5D5D60;
    text-align: justify;
    font-size: 1.25rem;
    line-height: 1.7;`,
    title: `
      color: #5D5D60;
      font-size: 3.125rem;
      margin-top: 0px;
    `,
    fontWeight: `font-weight: 600;`,
    labelNerito: `font-weight: 600;
    color:#1DB0B9;`,
    subtitle: `
      color: #5D5D60;
      font-size: 1.875rem;
      margin-top: 0px;
    `,
    textRight:`
      font-weight: 600;
      color:#5D5D60;
      text-align: right;
    `
}

const useStyles = makeStyles((theme) =>
    createStyles({
      container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        color: 'white',
        fontSize: '62px',
        minHeight: '100vh',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${Background})`,
        paddingTop: '5%',
        backgroundAttachment: "fixed"
      },
      contact: {
        display: 'flex',
        width: '90%',
        [theme.breakpoints.down('md')]: {
          width: '80%',
          // justifyContent: 'center',
          alignItems: 'center',
        },
      },
      blue: {
        color: theme.palette.blue.main,
      },
      title: {
        // width: '60%',
        padding: '0 0 5% 0',
        fontWeight: 900,
        [theme.breakpoints.down('md')]: {
          width: '100%',
          textAlign: 'center'
        },
      },
      containerForm: {
        display: 'flex',
        flexDirection: "column",
        alignContent: 'center',
        backgroundColor: theme.palette.white.main,
        borderRadius: '0.625rem',
        marginBottom: '6.25rem',
        padding: '5%',
        width: '100%',
        color: 'black',
        [theme.breakpoints.down('md')]: {
          padding: 20
        },
      }
    }),
);

export default Privacity