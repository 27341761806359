import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import Background from '../assets/images/blackbackground.png'
import PrimaryButton from './primaryButton'
import theme from '../styles/theme';

const SectionOne = () => {

  const classes = useStyles();


  return (
    <div className={classes.container}>
      <div className={classes.floatContainer}>
        <div className={classes.textContainer}>
          <div className={classes.firstText}>
            Liberamos tu <span className={classes.potential}>potencial</span> desde México para el mundo.
          </div>
          <div className={classes.secondText}>
            Queremos construir a tu lado un futuro financiero más inclusivo, transparente e interconectado.
          </div>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      color: 'white',
      fontSize: '62px',
      height: '100%',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${Background})`,
      padding: '15% 0 28% 0',
    },
    floatContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      top: '30%',
      width: '70%',

    },
    textContainer: {
      textAlign: 'center'
    },
    firstText: {
      fontSize: '62px',
      fontWeight: '800'
    },
    secondText: {
      fontSize: '28px',
      color: '#DEDEE3'
    },
    potential: {
      color: theme.palette.blue.main,
    }
  }),
);


export default SectionOne;