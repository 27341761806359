import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import Mockup from '../assets/images/Mockup.svg'
import Background from '../assets/images/blackbackground.svg'
import PrimaryButton from './primaryButton'
import theme from '../styles/theme';
import createAccount from '../assets/icons/createAccount.svg'
import happyFace from '../assets/icons/happyFace.svg'
import security from '../assets/icons/security.svg'
import Ecommerce from '../assets/icons/ecommerce.svg'
import Checkbox from '../assets/icons/checkbox.svg'
import Finance from '../assets/icons/finance.svg'


const SectionTwo = () => {

  const classes = useStyles();


  return (
    <div id="services" className={classes.container}>
      <div className={classes.textAndImage}>
        <div className={classes.textContainer}>
          <div className={classes.firstText}>
            FlexeDigital es una empresa <span className={classes.blue}>dinámica, joven, responsable</span> y <span className={classes.blue}>nativa digital.
            </span>
          </div>
          <div className={classes.secondText}>
            Nuestra visión es llevar las más avanzadas soluciones a familias,
            comercios y empresas de manera eficiente, cómoda y confiable.
          </div>
        </div>
        <div className={classes.imageContainer}>
          <img src={Mockup} alt="mockup" width={ theme.breakpoints.down('md') ? '80%' : '45%'}/>
        </div>
      </div>
      <div className={classes.iconsContainer}>
        <div className={classes.icon}>
          <img src={Ecommerce} alt="createAccount" width='20%' />
          <div className={classes.title}>
            Ecommerce
          </div>
          <div className={classes.text}>
            Oportunidades de crecimiento económico
            confiable para todo tipo de comercio y negocio.
          </div>
        </div>
        <div className={classes.icon}>
          <img src={Finance} alt="security" width='20%' />
          <div className={classes.title}>
            Inclusión Financiera
          </div>
          <div className={classes.text}>
            Productos y servicios financieros útiles y
            asequibles para tus necesidades de manera
            responsable y sostenible.
          </div>
        </div>
        <div className={classes.icon}>
          <img src={Checkbox} alt="happyFace" width='20%' />
          <div className={classes.title}>
            Servicios y consultorías de TI
          </div>
          <div className={classes.text}>
            Conocimiento tecnológico, de seguridad y
            alcance para mejorar los servicios financieros.
          </div>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '62px',
      height: '100%',
      flexDirection: 'column',
      backgroundSize: 'cover',
      padding: '10% 0 10% 0',
    },
    firstText: {
      fontSize: '50px',
      fontWeight: '800',
      width: '65%',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
    secondText: {
      paddingTop: '35px',
      fontSize: '28px',
      color: '#5D5D60 ',
      width: '65%',
      textAlign: 'justify',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
        textAlign: 'center',
      },
    },
    blue: {
      color: theme.palette.blue.main,
    },
    textAndImage: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        width: '100%',  
      },
    },
    imageContainer: {
      display: 'flex',
      textAlign: 'center',
      width: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        width: '80%',    
      },
    },
    iconsContainer: {
      display: 'flex',
      width: '80%',
      justifyContent: 'space-around',
      padding: '55px 0 55px 0',
      [theme.breakpoints.down('md')]: {
        width: '100%', 
      },
    },
    icon: {
      display: 'flex',
      flexDirection: 'column',
      width: '25%',
      alignItems: 'center',
      textAlign: 'center',
      gap: '15px'
    },
    title: {
      fontSize: '18px',
      fontWeight: 700,
    },
    text: {
      fontSize: '16px'
    }
  }),
);


export default SectionTwo;