import React, { useState, useLayoutEffect } from 'react';
import { makeStyles } from '@mui/styles';
// import Logo from '../../assets/images/Logo.svg'
import Logo from '../../assets/images/Logo.svg'
import '../../styles/styles.css'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { NavLink } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const useStyle = makeStyles(theme =>({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: "5.438rem",
        background: theme.palette.primary.main,
        position: 'sticky',
        top: 0,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            height: "10%",
        },
    },
    containerLogo: {
        background: theme.palette.white.main,
        width: '14.438rem',
        height: "5.438rem",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'black',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    containerMenu: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexDirection: 'row',
        width: '80%',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%'
        },
    },
    menu: {
        textDecoration: "none",
        color: theme.palette.white.main,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonSession: {
        textDecoration: "none",
        color: theme.palette.white.main,
        borderRadius: 50,
        backgroundColor: '#878787',
        textAlign: 'center',
        padding: '2% 8%'
    },
    signUp: {
        color: theme.palette.white.main
    },
    loginContainer: {
        display: 'flex',
        width: '20%',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center'
        },
    },
    menuBtn: {
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'block',
            backgroundColor: 'transparent',
            textAlign: 'center',
        },
    },
    closeMenu: {
        display: 'none'
    },
}))

const NavBar = () => { 
    const classes = useStyle();
    const [menu, setMenu] = useState(false)
    const [screen, setScreen] = useState(false)

    useLayoutEffect(() => {
        window.addEventListener('resize', getScreenSize);
        getScreenSize();
        return () => window.removeEventListener('resize', getScreenSize);
    }, []);

    const getScreenSize = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 899.95) {
          setScreen(false)
        } else {
          setScreen(true)
        }
    };
    
  return (
    <div className = {classes.container}>
        <div className = {classes.containerLogo}>
            <NavLink to = {'/'}>
                <img src={Logo} alt='Logo' width='100%'/>
            </NavLink>
        </div>
        <button className={classes.menuBtn} onClick={() => setMenu(!menu)}>{menu ? <CloseIcon className={classes.menu} fontSize="large"/> : <MenuIcon className={classes.menu} fontSize="large"/>}</button>
        <nav className={screen ? classes.containerMenu : menu ? classes.containerMenu : classes.closeMenu}>
            <a className={classes.menu} href='#services'>Servicios</a>
            <div className="dropdown">
                <a href='#groups'className={classes.menu}>Nuestros grupos<ArrowDropDownIcon/></a>
                <div className="lineDecoration"></div>
                    <div className="dropdown-content">
                        <div className = "dropdown-content-align">
                            <a href="#groups">Flexanalytics</a>
                            <a href="#groups">Nerito</a>
                            <a href="#groups">Nerito Pay</a>
                        </div>
                    </div>
            </div>
            <a className={classes.menu} href='#jobs'>Bolsa de trabajo</a>
            <a className={classes.menu} href='#contact'>Contacto</a>
        </nav>
    </div>
  )
};

export default NavBar;
