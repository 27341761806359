import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import Background from '../assets/images/blackbackground.svg'
import ContactForm from './contactForm'

const SectonFive = (props) => {
  const classes = useStyles();

  return (
    <div id='contact' className={classes.container}>
      <div className={classes.title}>
        Contacto<span className={classes.blue}>.</span>
      </div>
      <div className={classes.contact}>
        <ContactForm />
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      color: 'white',
      fontSize: '62px',
      height: '100%',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${Background})`,
      padding: '5% 0 5% 0',
    },
    contact: {
      display: 'flex',
      flexDirection: 'column',
      width: '60%',
      justifyContent: 'space-around',
      gap: '90px',
      [theme.breakpoints.down('md')]: {
        width: '80%',
        // justifyContent: 'center',
        alignItems: 'center',
      },
    },
    blue: {
      color: theme.palette.blue.main,
    },
    title: {
      width: '60%',
      padding: '0 0 5% 0',
      fontWeight: 900,
      [theme.breakpoints.down('md')]: {
        width: '100%',
        textAlign: 'center'
      },
    },

  }),
);


export default SectonFive;