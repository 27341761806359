import { ThemeProvider } from '@mui/material/styles';
import { Routes, Route, Navigate } from "react-router-dom";
import theme from './styles/theme'
import Landing from './pages/index.js'
import Privacity from './pages/privacity.js'
import { SnackbarProvider } from 'notistack';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={4}>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/privacity" element={<Privacity />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
